define("ember-sweetalert/services/swal", ["exports", "sweetalert2"], function (_exports, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SweetAlertService extends Ember.Service {
    constructor() {
      super(...arguments);

      _defineProperty(this, "sweetAlert", void 0);

      let config = Ember.getOwner(this).resolveRegistration('config:environment') || {};
      this.sweetAlert = _sweetalert.default.mixin(config['ember-sweetalert'] || {});
    }

    fire(...args) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.sweetAlert.fire(...args).then(resolve, reject);
      });
    }

    isVisible() {
      return this.sweetAlert.isVisible();
    }

    mixin(params) {
      return this.sweetAlert.mixin(params);
    }

    update(params) {
      return this.sweetAlert.update(params);
    }

    close() {
      this._run('close');
    }

    getContainer() {
      return this.sweetAlert.getContainer();
    }

    getHeader() {
      return this.sweetAlert.getHeader();
    }

    getTitle() {
      return this.sweetAlert.getTitle();
    }

    getProgressSteps() {
      return this.sweetAlert.getProgressSteps();
    }

    getCloseButton() {
      return this.sweetAlert.getCloseButton();
    }

    getContent() {
      return this.sweetAlert.getContent();
    }

    getImage() {
      return this.sweetAlert.getImage();
    }

    getActions() {
      return this.sweetAlert.getActions();
    }

    getFooter() {
      return this.sweetAlert.getFooter();
    }

    getFocusableElements() {
      return this.sweetAlert.getFocusableElements();
    }

    getConfirmButton() {
      return this.sweetAlert.getConfirmButton();
    }

    getDenyButton() {
      return this.sweetAlert.getDenyButton();
    }

    getCancelButton() {
      return this.sweetAlert.getCancelButton();
    }

    enableButtons() {
      this._run('enableButtons');
    }

    disableButtons() {
      this._run('disableButtons');
    }

    showLoading() {
      this._run('showLoading');
    }

    hideLoading() {
      this._run('hideLoading');
    }

    isLoading() {
      return this.sweetAlert.isLoading();
    }

    getTimerLeft() {
      return this.sweetAlert.getTimerLeft();
    }

    stopTimer() {
      return this.sweetAlert.stopTimer();
    }

    resumeTimer() {
      return this.sweetAlert.resumeTimer();
    }

    toggleTimer() {
      return this.sweetAlert.toggleTimer();
    }

    isTimerRunning() {
      return this.sweetAlert.isTimerRunning();
    }

    increaseTimer(n) {
      return this.sweetAlert.increaseTimer(n);
    }

    clickConfirm() {
      this._run('clickConfirm');
    }

    clickDeny() {
      this._run('clickDeny');
    }

    clickCancel() {
      this._run('clickCancel');
    }

    getInput() {
      return this.sweetAlert.getInput();
    }

    disableInput() {
      this._run('disableInput');
    }

    enableInput() {
      this._run('enableInput');
    }

    showValidationMessage(error) {
      this._run('showValidationMessage', error);
    }

    resetValidationMessage() {
      this._run('resetValidationMessage');
    }

    getValidationMessage() {
      return this.getValidationMessage();
    }

    queue() {
      this._run('queue', ...arguments);
    }

    getQueueStep() {
      return this.sweetAlert.getQueueStep();
    }

    insertQueueStep() {
      this._run('insertQueueStep', ...arguments);
    }

    deleteQueueStep(index) {
      this._run('deleteQueueStep', index);
    }

    isValidParameter(param) {
      return this.sweetAlert.isValidParameter(param);
    }

    isUpdatableParameter(param) {
      return this.sweetAlert.isUpdatableParameter(param);
    }

    _run(method, ...args) {
      Ember.run.scheduleOnce('afterRender', this.sweetAlert, method, ...args);
    }

  }

  _exports.default = SweetAlertService;
});