define('ember-data/-private', ['exports', '@ember-data/store/-private', '@ember-data/store', 'ember-data/version', '@ember-data/model'], function (exports, Private, store, VERSION, Model) { 'use strict';

  store = store && Object.prototype.hasOwnProperty.call(store, 'default') ? store['default'] : store;
  VERSION = VERSION && Object.prototype.hasOwnProperty.call(VERSION, 'default') ? VERSION['default'] : VERSION;
  Model = Model && Object.prototype.hasOwnProperty.call(Model, 'default') ? Model['default'] : Model;

  /**
    @module ember-data
  */

  /**
    All Ember Data classes, methods and functions are defined inside of this namespace.

    @class DS
    @static
  */

  /**
    @property VERSION
    @type String
    @static
  */

  var DS = Ember.Namespace.create({
    VERSION: VERSION,
    name: 'DS'
  });

  if (Ember.libraries) {
    Ember.libraries.registerCoreLibrary('Ember Data', DS.VERSION);
  }

  function featureIsEnabled() {
    return Ember.FEATURES.isEnabled(...arguments);
  }

  /*
    Extend `Ember.DataAdapter` with ED specific code.

    @class DebugAdapter
    @extends Ember.DataAdapter
    @private
  */

  var debugAdapter = Ember.DataAdapter.extend({
    getFilters() {
      return [{
        name: 'isNew',
        desc: 'New'
      }, {
        name: 'isModified',
        desc: 'Modified'
      }, {
        name: 'isClean',
        desc: 'Clean'
      }];
    },

    detect(typeClass) {
      return typeClass !== Model && Model.detect(typeClass);
    },

    columnNameToDesc(name) {
      return Ember.String.capitalize(Ember.String.underscore(name).replace(/_/g, ' ').trim());
    },

    columnsForType(typeClass) {
      var columns = [{
        name: 'id',
        desc: 'Id'
      }];
      var count = 0;
      var self = this;
      Ember.get(typeClass, 'attributes').forEach((meta, name) => {
        if (count++ > self.attributeLimit) {
          return false;
        }

        var desc = this.columnNameToDesc(name);
        columns.push({
          name: name,
          desc: desc
        });
      });
      return columns;
    },

    getRecords(modelClass, modelName) {
      if (arguments.length < 2) {
        // Legacy Ember.js < 1.13 support
        var containerKey = modelClass._debugContainerKey;

        if (containerKey) {
          var match = containerKey.match(/model:(.*)/);

          if (match !== null) {
            modelName = match[1];
          }
        }
      }

      ( !(!!modelName) && Ember.assert('Cannot find model name. Please upgrade to Ember.js >= 1.13 for Ember Inspector support', !!modelName));
      return this.get('store').peekAll(modelName);
    },

    getRecordColumnValues(record) {
      var count = 0;
      var columnValues = {
        id: Ember.get(record, 'id')
      };
      record.eachAttribute(key => {
        if (count++ > this.attributeLimit) {
          return false;
        }

        columnValues[key] = Ember.get(record, key);
      });
      return columnValues;
    },

    getRecordKeywords(record) {
      var keywords = [];
      var keys = Ember.A(['id']);
      record.eachAttribute(key => keys.push(key));
      keys.forEach(key => keywords.push(Ember.get(record, key)));
      return keywords;
    },

    getRecordFilterValues(record) {
      return {
        isNew: record.get('isNew'),
        isModified: record.get('hasDirtyAttributes') && !record.get('isNew'),
        isClean: !record.get('hasDirtyAttributes')
      };
    },

    getRecordColor(record) {
      var color = 'black';

      if (record.get('isNew')) {
        color = 'green';
      } else if (record.get('hasDirtyAttributes')) {
        color = 'blue';
      }

      return color;
    },

    observeRecord(record, recordUpdated) {
      var releaseMethods = Ember.A();
      var keysToObserve = Ember.A(['id', 'isNew', 'hasDirtyAttributes']);
      record.eachAttribute(key => keysToObserve.push(key));
      var adapter = this;
      keysToObserve.forEach(function (key) {
        var handler = function () {
          recordUpdated(adapter.wrapRecord(record));
        };

        Ember.addObserver(record, key, handler);
        releaseMethods.push(function () {
          Ember.removeObserver(record, key, handler);
        });
      });

      var release = function () {
        releaseMethods.forEach(fn => fn());
      };

      return release;
    }

  });

  Object.defineProperty(exports, 'AdapterPopulatedRecordArray', {
    enumerable: true,
    get: function () {
      return Private.AdapterPopulatedRecordArray;
    }
  });
  Object.defineProperty(exports, 'Errors', {
    enumerable: true,
    get: function () {
      return Private.Errors;
    }
  });
  Object.defineProperty(exports, 'InternalModel', {
    enumerable: true,
    get: function () {
      return Private.InternalModel;
    }
  });
  Object.defineProperty(exports, 'ManyArray', {
    enumerable: true,
    get: function () {
      return Private.ManyArray;
    }
  });
  Object.defineProperty(exports, 'PromiseArray', {
    enumerable: true,
    get: function () {
      return Private.PromiseArray;
    }
  });
  Object.defineProperty(exports, 'PromiseManyArray', {
    enumerable: true,
    get: function () {
      return Private.PromiseManyArray;
    }
  });
  Object.defineProperty(exports, 'PromiseObject', {
    enumerable: true,
    get: function () {
      return Private.PromiseObject;
    }
  });
  Object.defineProperty(exports, 'RecordArray', {
    enumerable: true,
    get: function () {
      return Private.RecordArray;
    }
  });
  Object.defineProperty(exports, 'RecordArrayManager', {
    enumerable: true,
    get: function () {
      return Private.RecordArrayManager;
    }
  });
  Object.defineProperty(exports, 'RecordData', {
    enumerable: true,
    get: function () {
      return Private.RecordData;
    }
  });
  Object.defineProperty(exports, 'Relationship', {
    enumerable: true,
    get: function () {
      return Private.Relationship;
    }
  });
  Object.defineProperty(exports, 'RootState', {
    enumerable: true,
    get: function () {
      return Private.RootState;
    }
  });
  Object.defineProperty(exports, 'Snapshot', {
    enumerable: true,
    get: function () {
      return Private.Snapshot;
    }
  });
  Object.defineProperty(exports, 'SnapshotRecordArray', {
    enumerable: true,
    get: function () {
      return Private.SnapshotRecordArray;
    }
  });
  Object.defineProperty(exports, 'coerceId', {
    enumerable: true,
    get: function () {
      return Private.coerceId;
    }
  });
  Object.defineProperty(exports, 'normalizeModelName', {
    enumerable: true,
    get: function () {
      return Private.normalizeModelName;
    }
  });
  Object.defineProperty(exports, 'recordDataFor', {
    enumerable: true,
    get: function () {
      return Private.recordDataFor;
    }
  });
  Object.defineProperty(exports, 'relationshipStateFor', {
    enumerable: true,
    get: function () {
      return Private.relationshipStateFor;
    }
  });
  Object.defineProperty(exports, 'relationshipsFor', {
    enumerable: true,
    get: function () {
      return Private.relationshipsFor;
    }
  });
  exports.Store = store;
  exports.DS = DS;
  exports.DebugAdapter = debugAdapter;
  exports.isEnabled = featureIsEnabled;

  Object.defineProperty(exports, '__esModule', { value: true });

});
