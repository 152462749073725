define("ember-mask-input/components/ember-mask-input", ["exports", "@glimmer/component", "ember-mask-input/masks", "jquery"], function (_exports, _component, _masks, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <input ...attributes id={{this.maskInputId}} value={{@value}}
    {{did-insert this.maskFunc this._mask this._clearIfNotMatch this._selectOnFocus}}
    {{did-update this.fillUnmaskedValueAsMasked this._mask this.selectionStartPosition this.selectionEndPosition this.maskType @value}}
    {{on 'change' this._domValueChanged}}
    {{on 'input' this._domValueChanged}}/>
  */
  {
    id: "SlL4oJwq",
    block: "{\"symbols\":[\"&attrs\",\"@value\"],\"statements\":[[7,\"input\",false],[13,1],[12,\"id\",[23,0,[\"maskInputId\"]]],[12,\"value\",[23,2,[]]],[3,\"did-insert\",[[23,0,[\"maskFunc\"]],[23,0,[\"_mask\"]],[23,0,[\"_clearIfNotMatch\"]],[23,0,[\"_selectOnFocus\"]]]],[3,\"did-update\",[[23,0,[\"fillUnmaskedValueAsMasked\"]],[23,0,[\"_mask\"]],[23,0,[\"selectionStartPosition\"]],[23,0,[\"selectionEndPosition\"]],[23,0,[\"maskType\"]],[23,2,[]]]],[3,\"on\",[\"change\",[23,0,[\"_domValueChanged\"]]]],[3,\"on\",[\"input\",[23,0,[\"_domValueChanged\"]]]],[8],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "ember-mask-input/components/ember-mask-input.hbs"
    }
  });

  let EmberMaskInput = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class EmberMaskInput extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "selectionStartPosition", _descriptor, this);

      _initializerDefineProperty(this, "selectionEndPosition", _descriptor2, this);
    }

    get maskInputId() {
      let maskInputId = this.args.id;

      if (Ember.isBlank(maskInputId)) {
        maskInputId = 'maskInput-' + Ember.guidFor(this);
      }

      return maskInputId;
    }

    get _mask() {
      let mask = null;
      let type = this.args.maskType;

      if (!Ember.isBlank(type)) {
        mask = _masks.MASK_MAPPING[type];
      }

      return Ember.isBlank(mask) ? this.args.mask : mask;
    }

    get maskType() {
      return this.args.maskType;
    }

    get _clearIfNotMatch() {
      return Ember.isPresent(this.args.clearIfNotMatch) ? this.args.clearIfNotMatch : false;
    }

    get _selectOnFocus() {
      return Ember.isPresent(this.args.selectOnFocus) ? this.args.selectOnFocus : false;
    }

    maskFunc(element, [mask, clearIfNotMatch, selectOnFocus]) {
      if (Ember.isPresent(mask)) {
        (0, _jquery.default)(element).mask(mask, {
          clearIfNotMatch: clearIfNotMatch,
          selectOnFocus: selectOnFocus
        });
      }
    }

    fillUnmaskedValueAsMasked(element, [mask, selectionStartPosition, selectionEndPosition, maskType]) {
      if (Ember.isPresent(mask)) {
        let unmaskedValue = (0, _jquery.default)(element).cleanVal();

        if (maskType === 'iban') {
          unmaskedValue = String(unmaskedValue).toUpperCase();
        }

        let maskedValue = (0, _jquery.default)(element).masked(unmaskedValue);
        (0, _jquery.default)(element).val(maskedValue);
        (0, _jquery.default)(element).change(); // restore from variables...

        (0, _jquery.default)(element)[0].setSelectionRange(selectionStartPosition, selectionEndPosition);
      }
    }

    _domValueChanged() {
      let maskInput = (0, _jquery.default)('#' + this.maskInputId);
      let unmaskedValue = Ember.isPresent(this._mask) ? maskInput.cleanVal() : this.args.value;
      let maskedValue = Ember.isPresent(this._mask) ? maskInput.masked(maskInput.val()) : this.args.value;
      let valueToBeSent = this.args.bindMasked === true ? maskedValue : unmaskedValue; // store current positions in variables

      let selectionStartPosition = maskInput[0].selectionStart;
      let selectionEndPosition = maskInput[0].selectionEnd;

      if (this.args.maskType === 'iban') {
        valueToBeSent = String(valueToBeSent).toUpperCase();
      }

      let oldValue = this.args.value;

      if (valueToBeSent !== oldValue && !(Ember.isEmpty(valueToBeSent) && Ember.isEmpty(oldValue))) {
        if (Ember.isEmpty(valueToBeSent)) {
          valueToBeSent = '';
        }

        this.selectionStartPosition = selectionStartPosition;
        this.selectionEndPosition = selectionEndPosition;
        let onUpdate = this.args.onUpdate;

        if (onUpdate) {
          onUpdate(valueToBeSent);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectionStartPosition", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectionEndPosition", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_domValueChanged", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_domValueChanged"), _class.prototype)), _class));
  _exports.default = EmberMaskInput;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EmberMaskInput);
});