define('ember-emojipalette/components/emoji-list/component', ['exports', 'ember-emojipalette/components/emoji-list/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    actions: {
      selectEmoji(emoji) {
        this.selectEmoji(emoji);
      }
    }
  });
});