define('ember-emojipalette/components/emoji-palette/component', ['exports', 'ember-emojipalette/components/emoji-palette/template', 'ember-emojipalette/utils/emojidex', 'ember-emojipalette/utils/category-icons'], function (exports, _template, _emojidex, _categoryIcons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    closeOnEsc: true,
    closeOnBackdropClick: false,
    searchDebounce: 500,

    // Event Handlers
    handleKeyDown(e) {
      let code = e.keyCode || e.which;
      if (code === 27 && this.get('closeOnEsc')) {
        this.get('onClose')();
      }
    },
    handleClick(e) {
      if (this.get('closeOnBackdropClick') && (e.target === this.element || !e.target.closest('.emojidex-palette-wrapper'))) {
        this.get('onClose')();
      }
    },

    // Lifecycle Hooks
    init() {
      this._super(...arguments);
      this.set('keyDownHandler', this.get('handleKeyDown').bind(this));
      this.set('clickHandler', this.get('handleClick').bind(this));
    },
    didInsertElement() {
      this._super(...arguments);
      if (this.get('emojiVersion')) {
        _emojidex.emojidex.setFilteredEmojiData(this.get('emojiVersion'));
      }
      Ember.run.later(() => {
        document.addEventListener('keydown', this.get('keyDownHandler'));
        document.addEventListener('click', this.get('clickHandler'));
      }, 1);
    },
    willDestroyElement() {
      this._super(...arguments);
      document.removeEventListener('keyDown', this.get('keyDownHandler'));
      document.removeEventListener('click', this.get('clickHandler'));
    },

    // Component Properties
    categoryNames: _emojidex.emojidex.getCategoryNames(),
    categorySVG: _categoryIcons.categoryIcons,
    searchTerm: '',
    searchPlaceholder: 'search for emoji',

    // Computed Properties
    emojis: Ember.computed(function () {
      return this.get('emojiVersion') ? _emojidex.emojidex.getFilteredEmojiList(this.get('emojiVersion')) : _emojidex.emojidex.emojilist;
    }),
    emojiList: Ember.computed('searchResults', 'currentListType', function () {
      return this.get('searchEnabled') && this.get('isSearchResult') ? this.get('searchResults') : this.get('isCompleteList') ? this.get('emojis') : this.get('emojis')[this.get('currentListType')];
    }),
    isCompleteList: Ember.computed.alias('hideCategories'),
    isSearchResult: Ember.computed.notEmpty('searchResults'),
    currentListType: Ember.computed(function () {
      return this.get('isCompleteList') ? 'all' : 'people';
    }),
    allowedCategories: Ember.computed(function () {
      const excludedCategories = this.get('excludedCategories');
      const categoryList = Object.keys(this.get('categoryNames'));
      return excludedCategories ? categoryList.filter(category => !this.excludedCategories.includes(category)) : categoryList;
    }),

    // functions
    searchEmojis() {
      const searchTerm = this.get('searchTerm');
      if (searchTerm.trim() === "") {
        this.set('searchResults', null);
        return;
      }
      const searchResults = _emojidex.emojidex.searchEmojis(searchTerm);
      if (searchResults.length !== 0) {
        this.set('searchResults', searchResults);
      } else {
        this.set('searchResults', 'none');
      }
    },

    // Actions
    actions: {
      searchEmojis() {
        Ember.run.debounce(this, this.searchEmojis, this.searchDebounce);
      },
      changeCategory(category) {
        this.set('currentListType', category);
      },
      selectEmoji(emoji) {
        this.set('selectedEmoji', emoji);
      }
    }
  });
});