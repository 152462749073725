define("ember-power-select/components/power-select/search-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="ember-power-select-options" role="listbox">
    <li class="ember-power-select-option ember-power-select-option--search-message" role="option">
      {{@searchMessage}}
    </li>
  </ul>
  */
  {
    "id": "R9f526S/",
    "block": "{\"symbols\":[\"@searchMessage\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"ember-power-select-options\"],[10,\"role\",\"listbox\"],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"ember-power-select-option ember-power-select-option--search-message\"],[10,\"role\",\"option\"],[8],[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/components/power-select/search-message.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});