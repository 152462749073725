define("ember-cli-clipboard/templates/components/copy-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QNw9di0r",
    "block": "{\"symbols\":[\"@aria-label\",\"@classNames\",\"@class\",\"@disabled\",\"@title\",\"&attrs\",\"@clipboardText\",\"@clipboardTarget\",\"@clipboardAction\",\"@buttonType\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"aria-label\",[23,1,[]]],[12,\"class\",[29,[\"copy-btn \",[23,3,[]],\" \",[23,2,[]]]]],[12,\"disabled\",[23,4,[]]],[12,\"title\",[23,5,[]]],[13,6],[12,\"data-clipboard-text\",[23,7,[]]],[12,\"data-clipboard-target\",[23,8,[]]],[12,\"data-clipboard-action\",[23,9,[]]],[12,\"type\",[28,\"if\",[[23,10,[]],[23,10,[]],\"button\"],null]],[3,\"did-insert\",[[23,0,[\"setupElement\"]]]],[3,\"did-insert\",[[23,0,[\"registerClipboard\"]]]],[3,\"did-update\",[[23,0,[\"registerClipboard\"]]]],[3,\"will-destroy\",[[23,0,[\"destroyClipboard\"]]]],[8],[0,\"\\n  \"],[14,11],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-clipboard/templates/components/copy-button.hbs"
    }
  });

  _exports.default = _default;
});