define("ember-power-select/components/power-select/before-options", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @searchEnabled}}
    <div class="ember-power-select-search">
      <input type="search" autocomplete="off"
        autocorrect="off" autocapitalize="off"
        spellcheck={{false}} role="combobox"
        class="ember-power-select-search-input"
        value={{@select.searchText}}
        aria-controls={{@listboxId}}
        placeholder={{@searchPlaceholder}}
        {{on "input" @onInput}}
        {{on "focus" @onFocus}}
        {{on "blur" @onBlur}}
        {{on "keydown" this.handleKeydown}}
        {{did-insert this.focusInput}}
        {{will-destroy this.clearSearch}}>
    </div>
  {{/if}}
  */
  {
    "id": "cg+Eiqnb",
    "block": "{\"symbols\":[\"@select\",\"@listboxId\",\"@searchPlaceholder\",\"@onInput\",\"@onFocus\",\"@onBlur\",\"@searchEnabled\"],\"statements\":[[4,\"if\",[[23,7,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ember-power-select-search\"],[8],[0,\"\\n    \"],[7,\"input\",false],[12,\"autocomplete\",\"off\"],[12,\"autocorrect\",\"off\"],[12,\"autocapitalize\",\"off\"],[12,\"spellcheck\",false],[12,\"role\",\"combobox\"],[12,\"class\",\"ember-power-select-search-input\"],[12,\"value\",[23,1,[\"searchText\"]]],[12,\"aria-controls\",[23,2,[]]],[12,\"placeholder\",[23,3,[]]],[12,\"type\",\"search\"],[3,\"on\",[\"input\",[23,4,[]]]],[3,\"on\",[\"focus\",[23,5,[]]]],[3,\"on\",[\"blur\",[23,6,[]]]],[3,\"on\",[\"keydown\",[23,0,[\"handleKeydown\"]]]],[3,\"did-insert\",[[23,0,[\"focusInput\"]]]],[3,\"will-destroy\",[[23,0,[\"clearSearch\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/components/power-select/before-options.hbs"
    }
  });

  let BeforeOptions = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class BeforeOptions extends _component.default {
    clearSearch() {
      Ember.run.scheduleOnce('actions', this.args.select.actions, 'search', '');
    }

    handleKeydown(e) {
      if (this.args.onKeydown(e) === false) {
        return false;
      }

      if (e.keyCode === 13) {
        this.args.select.actions.close(e);
      }
    }

    focusInput(el) {
      Ember.run.later(() => {
        if (this.args.autofocus !== false) {
          el.focus();
        }
      }, 0);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "clearSearch", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clearSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeydown", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "focusInput"), _class.prototype)), _class));
  _exports.default = BeforeOptions;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BeforeOptions);
});