define("ember-sweetalert/components/sweet-alert", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span
    aria-hidden="true"
    {{did-insert this.fire this.isOpen}}
    {{did-update this.fire this.isOpen}}
    ...attributes
  ></span>
  
  */
  {
    "id": "JVj5Vzen",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"span\",false],[12,\"aria-hidden\",\"true\"],[13,1],[3,\"did-insert\",[[23,0,[\"fire\"]],[23,0,[\"isOpen\"]]]],[3,\"did-update\",[[23,0,[\"fire\"]],[23,0,[\"isOpen\"]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-sweetalert/components/sweet-alert.hbs"
    }
  });

  const CONFIGURATION = ['title', 'titleText', 'html', 'text', 'icon', 'iconColor', 'iconHtml', 'showClass', 'hideClass', 'footer', 'backdrop', 'toast', 'target', 'input', 'width', 'padding', 'background', 'position', 'grow', 'customClass', 'timer', 'timerProgressBar', 'animation', 'heightAuto', 'allowOutsideClick', 'allowEscapeKey', 'allowEnterKey', 'stopKeydownPropagation', 'keydownListenerCapture', 'showConfirmButton', 'showDenyButton', 'showCancelButton', 'confirmButtonText', 'denyButtonText', 'cancelButtonText', 'confirmButtonColor', 'denyButtonColor', 'cancelButtonColor', 'confirmButtonAriaLabel', 'denyButtonAriaLabel', 'cancelButtonAriaLabel', 'buttonsStyling', 'reverseButtons', 'focusConfirm', 'focusDeny', 'focusCancel', 'showCloseButton', 'closeButtonHtml', 'closeButtonAriaLabel', 'loaderHtml', 'showLoaderOnConfirm', 'showLoaderOnDeny', 'scrollbarPadding', 'preConfirm', 'preDeny', 'returnInputValueOnDeny', 'imageUrl', 'imageWidth', 'imageHeight', 'imageAlt', 'imageLabel', 'inputPlaceholder', 'inputValue', 'inputOptions', 'inputAutoTrim', 'inputAttributes', 'inputValidator', 'validationMessage', 'progressSteps', 'currentProgressStep', 'progressStepsDistance'];
  const EVENTS = ['willOpen', 'onBeforeOpen', // deprecated, use willOpen
  'didOpen', 'onOpen', // deprecated, use didOpen
  'didRender', 'onRender', // deprecated, use didRender
  'willClose', 'onClose', // deprecated, use willClose
  'didClose', 'onAfterClose', // deprecated, use didClose,
  'didDestroy', 'onDestroy' // deprecated, use didDestroy
  ];
  let SweetAlertComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class SweetAlertComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "swal", _descriptor, this);
    }

    get isOpen() {
      if (undefined === this.args.show) {
        return true;
      }

      return this.args.show;
    }

    async fire(element, [open]) {
      if (open) {
        let result = await this.swal.fire(this._values());

        if (result.value) {
          this._call('onConfirm', result);

          return;
        }

        this._call('onCancel', result);
      }
    }

    _call(method, ...args) {
      if (!this.isDestroying && this.args[method]) {
        this.args[method](...args);
      }
    }

    _values() {
      let props = {};
      Ember.A(CONFIGURATION).forEach(key => {
        let value = this.args[key];

        if (undefined !== value) {
          props[key] = value;
        }
      });
      Ember.A(EVENTS).forEach(key => {
        if (undefined !== this.args[key]) {
          props[key] = () => this._call(key, ...arguments);
        }
      });
      return props;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fire", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fire"), _class.prototype)), _class));
  _exports.default = SweetAlertComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SweetAlertComponent);
});