define("ember-basic-dropdown/templates/components/basic-dropdown-optional-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oRZ0OOA/",
    "block": "{\"symbols\":[\"Element\",\"&attrs\",\"&default\",\"@htmlTag\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"ensure-safe-component\",[[28,\"-element\",[[23,4,[]]],null]],null]],[[\"tagName\"],[[23,4,[]]]]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[[13,2]],[[],[]],{\"statements\":[[0,\"\\n      \"],[14,3],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",false],[13,2],[8],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown-optional-tag.hbs"
    }
  });

  _exports.default = _default;
});