define("ember-power-select/components/power-select/no-matches-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @noMatchesMessage}}
    <ul class="ember-power-select-options" role="listbox">
      <li class="ember-power-select-option ember-power-select-option--no-matches-message" role="option">
        {{@noMatchesMessage}}
      </li>
    </ul>
  {{/if}}
  */
  {
    "id": "l6KlaR8v",
    "block": "{\"symbols\":[\"@noMatchesMessage\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"ember-power-select-options\"],[10,\"role\",\"listbox\"],[8],[0,\"\\n    \"],[7,\"li\",true],[10,\"class\",\"ember-power-select-option ember-power-select-option--no-matches-message\"],[10,\"role\",\"option\"],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/components/power-select/no-matches-message.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});